import React, { useEffect } from "react"
import modules from "./Contact.module.scss"
import Img from 'gatsby-image'

import phone from "../../images/phone.svg"
import drawer_pin from "../../images/drawer_pin.svg"
import calendar from "../../images/calendar.svg"
import clock from "../../images/clock.svg"
import envelope from "../../images/envelope.svg"
import info from "../../images/info.svg"
import rehabilitation from "../../images/rehabilitation.svg"
import AOS from "aos"
import "aos/dist/aos.css"

const generateDays = () => {
  const days = ["Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek"]
  return days.map(value => <p key={value}>{value}</p>)
}

const generateHours = data => {
  const hours = ["pn", "wt", "sr", "czw", "pt"]
  return hours.map(value => <p key={value}>{data[value]}</p>)
}

const Contact = ({ contact, hours, building }) => {
  useEffect(() => {
    AOS.init({
      duration: 500,
    })
  }, [])
  return (
    <div className={modules.container}>
      <h1 data-aos="fade-left">KONTAKT</h1>
      <div className={modules.phone} data-aos="fade-up">
        <img src={phone} alt={phone}></img>
        <div>
          <a href={"tel:" + contact.rejestracja_telefon}>
            <span className={modules.phone_owner}>Rejestracja</span>
            <span>{` tel. ${contact.rejestracja_telefon}`}</span>
          </a>
        </div>
      </div>
      <div className={modules.data_grid} data-aos="fade-right">
        <div className={modules.data_object}>
          <img src={drawer_pin} alt={drawer_pin}></img>
          <div className={modules.tekst}>
            <a
              href={`https://www.google.pl/maps/place/${contact.ulica}+${contact.numer_budynku},${contact.miasto}/`}
            >
              <h6>Zakład rehabilitacji Leczniczej REH-MED</h6>
              <p>{`ul.${contact.ulica} ${contact.numer_budynku}`}</p>
              <p>{`${contact.kod_pocztowy} ${contact.miasto}`}</p>
              <p>{`${contact.adres_opis}`}</p>
            </a>
          </div>
        </div>
        <Img className={modules.building__photo} fluid={building.childImageSharp.fluid}/>
        <div className={modules.data_object} data-aos="fade-right">
          <img src={envelope} alt={envelope}></img>
          <a href={"mailto:" + contact.email}>
            <p>{`${contact.email}`}</p>
          </a>
        </div>
        <div className={modules.data_object} data-aos="fade-right">
          <img src={info} alt={info}></img>
          <p>{`NIP ${contact.nip}, Regon ${contact.regon}`}</p>
        </div>
        <div className={modules.data_object} data-aos="fade-right">
          <img src={clock} alt={clock}></img>
          <div className={modules.tekst}>
            <h4>Godziny przyjęć</h4>
            <p>{`${contact.godziny_przyjec_opis}`}</p>
            <p>{`${contact.godziny_przyjec}`}</p>
          </div>
        </div>
        <div className={modules.data_object} data-aos="fade-right">
          <img src={rehabilitation} alt={rehabilitation}></img>
          <div className={modules.tekst}>
            <h4>Poradnia rehabilitacyjna</h4>
            <p>{`${contact.poradnia_reh_opis}`}</p>
          </div>
        </div>
        <div className={modules.data_object} data-aos="fade-right">
          <img src={calendar} alt={calendar}></img>
          <div className={modules.tekst}>
            <h4>Rejestracja</h4>
            <div className={modules.register}>
              <div className={modules.days}>{generateDays()}</div>
              <div className={modules.hours}>{generateHours(hours)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact

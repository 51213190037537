import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"

import Contact from "../components/Contact/Contact"
import SEO from "../components/seo"

const ContactPage = ({ data }) => (
  <Layout>
    <SEO
      title="Kontakt"
      keywords="Fizjoterapia Legnica,
       Fizjoterapeuta Legnica,
        Fizjoterapeutka Legnica,
         Masaż Legnica, Masażysta Legnica,
          Masażystka Legnica, Hydroterapia Legnica, Kinezyterapia Legnica,
           lekarz Legnica, terapeuta Legnica, ból pleców Legnica, masaże Legnica,
            rehabilitacja Legnica, Anna Mysyk Legnica, Anna Mysyk Legnica Rehabilitacja,"
    />

    <Contact
      contact={data.strapiKontakt}
      hours={data.strapiGodzinyRejestracji}
      building={data.mapa}
    />
  </Layout>
)

export default ContactPage

export const contactQuery = graphql`
  query ContactQuery {
    strapiKontakt {
      adres_opis
      email
      godziny_przyjec
      godziny_przyjec_opis
      kod_pocztowy
      miasto
      nip
      numer_budynku
      poradnia_reh_opis
      regon
      rejestracja_telefon
      telefon_sala_gim
      ulica
    }
    strapiGodzinyRejestracji {
      czw
      nd
      pn
      pt
      sb
      sr
      wt
    }
    mapa: file(relativePath: { eq: "budynek.png" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
